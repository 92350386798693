<footer class="bg-white w-full border-t-[1px]">
  <div class="w-full container py-10 mx-auto">
    <!-- Grid -->
    <div class="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-6 lg:gap-12">
      <div class="col-span-full lg:col-span-2 lg:pr-5">
        <img src="assets/SoowGood.png" alt="logo" class="max-w-[200px] lg:max-w-[250px]" />
        <p class="font-poppins mt-3 text-sm text-primary/80">
          The All-in-One Healthcare Platform in Bangladesh.
        </p>
        <ul class="font-poppins text-primary/80 mt-5">
          <li class="mt-5 text-[14px]">
            <i class="fa-solid fa-phone mr-2 text-secondary"></i>09617-323232
          </li>
          <li class="mt-3 text-[14px]">
            <i class="fa-solid fa-paper-plane mr-2 text-secondary"></i>info&#64;soowgood.com
          </li>
          <li class="flex items-center mt-3 text-[14px]">
            <i class="fa-solid fa-location-dot mr-2 text-secondary"></i>
            <span>572/K, Wasi Tower, (12th Floor) Matikata Road, ECB Chattar, Dhaka.</span>
          </li>
        </ul>
      </div>
      <!-- End Col -->

      <div class="col-span-2 mt-5 font-poppins">
        <h4 class="font-semibold text-secondary text-[18px]">Company</h4>

        <ul class="mt-3 space-y-3 text-primary sm:columns-2 columns-1">
          <li class="link link-hover opacity-80 text-[14px]" routerLink="/about-us">
            About us
          </li>
          <li class="link link-hover opacity-80 text-[14px]" routerLink="/contact-us">
            Support
          </li>
          <li routerLink="/soowgood-point" class="link link-hover opacity-80 text-[14px]">
            Soowgood points
          </li>
          <li routerLink="/soowgood-booth" class="link link-hover opacity-80 text-[14px]">
            Village booths
          </li>
          <li class="link link-hover opacity-80 text-[14px]" routerLink="/privacy">
            Privacy policy
          </li>
          <li class="link link-hover opacity-80 text-[14px]" routerLink="/terms-services">
            Terms & Condition
          </li>
          <li class="link link-hover opacity-80 text-[14px]" routerLink="/refund-policy">
            Refund Policy
          </li>
          <li class="link link-hover opacity-80 text-[14px]" routerLink="/acoount-delete-request">
            Acount Delete Request
          </li>
        </ul>
      </div>

      <div class="col-span-2 mt-3 lg:mt-0">
        <div class="w-full">
          <iframe class="w-full"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d542.9253908595066!2d90.41014204130393!3d23.734819665616723!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755c567062a9823%3A0xcaf61b51dea4ccf4!2sCoppanet%20Limited!5e0!3m2!1sen!2sbd!4v1709643359256!5m2!1sen!2sbd"
            width="320" height="250" style="border: 0" allowfullscreen="" loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </div>
      <!-- End Col -->
    </div>
    <!-- End Grid -->
  </div>
</footer>

<footer class="bg-white font-poppinsLight">
  <div class="container">
    <img src="/assets/other/ssl.png" alt="ssl-banner" />
  </div>
  <div class="container mt-5 footer py-6 border-t text-primary border-base-300">
    <div class="items-center grid-flow-col">
      <!-- <img src="assets/SoowGood-Icon.png" width="30px" alt=""> -->
      <div>
        <p class="mt-4">Providing reliable health solution since 2021</p>
        <p>Developed by Coppanet©all right reserved 2024</p>
      </div>
    </div>
    <div class="md:place-self-center md:justify-self-end">
      <div class="grid grid-flow-col gap-4">
        <a href="https://www.youtube.com/@soowgoodbd7208"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
            viewBox="0 0 24 24" class="fill-current">
            <path
              d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z">
            </path>
          </svg></a>
        <a href="https://www.linkedin.com/company/soowgoodbd/">
          <svg fill="#000000" height="24px" width="24px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="-143 145 512 512" xml:space="preserve">
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
            <g id="SVGRepo_iconCarrier">
              <path
                d="M329,145h-432c-22.1,0-40,17.9-40,40v432c0,22.1,17.9,40,40,40h432c22.1,0,40-17.9,40-40V185C369,162.9,351.1,145,329,145z M41.4,508.1H-8.5V348.4h49.9V508.1z M15.1,328.4h-0.4c-18.1,0-29.8-12.2-29.8-27.7c0-15.8,12.1-27.7,30.5-27.7 c18.4,0,29.7,11.9,30.1,27.7C45.6,316.1,33.9,328.4,15.1,328.4z M241,508.1h-56.6v-82.6c0-21.6-8.8-36.4-28.3-36.4 c-14.9,0-23.2,10-27,19.6c-1.4,3.4-1.2,8.2-1.2,13.1v86.3H71.8c0,0,0.7-146.4,0-159.7h56.1v25.1c3.3-11,21.2-26.6,49.8-26.6 c35.5,0,63.3,23,63.3,72.4V508.1z">
              </path>
            </g>
          </svg>
        </a>

        <a href="https://www.facebook.com/soowgoodbd"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
            viewBox="0 0 24 24" class="fill-current">
            <path
              d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z">
            </path>
          </svg></a>
      </div>
    </div>
  </div>
</footer>