import {
  DoctorProfileService,
  PatientProfileService,
} from '../../../../../proxy/services';
import { UserManageAccountsService } from './../../../../../proxy/services/user-manage-accounts.service';

import { Component, OnDestroy, OnInit, signal } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
// import * as CryptoJS from 'crypto-js';

import { AuthService } from 'src/app/shared/services/auth.service';
import { USER_SECRATE } from 'src/environments/environment';
// import { SubSink } from 'subsink';

import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { NgOtpInputModule } from 'ng-otp-input';
import { LoginResponseDto, PatientProfileDto } from 'src/app/proxy/dto-models';
import { AuthService as Auth } from 'src/app/proxy/services';
import { OtpRequestDto } from 'src/app/proxy/soow-good/domain/service/models/otp';
import {
  SendOtpModel,
  UserSignInRequestDto,
} from 'src/app/proxy/soow-good/domain/service/models/user-info';
import { TosterService } from 'src/app/shared/services/toster.service';
import { OtpComponent } from '../../../components/otp/otp.component';

// import { DoctorProfileDto, PatientProfileDto } from 'src/app/proxy/dto-models';
export interface ExtendedLoginDto {
  userName: string;
  password: string;
  otp: string;
}

const loginDto: ExtendedLoginDto = {
  userName: '',
  password: '',
  otp: '',
};

type Otp = {
  pin: number;
  showOtp: boolean;
  isLoading: boolean;
  otpError: string | undefined;
  otpSuccess: string | undefined;
};

@Component({
  selector: 'app-login-input',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    NgOtpInputModule,
    RouterModule,
    OtpComponent,
  ],
  templateUrl: './login-input.component.html',
  styleUrl: './login-input.component.scss',
})
export class LoginInputComponent implements OnInit, OnDestroy {
  selectedUserType = '';
  formSubmitted: boolean = false;
  // errorMessage: string = '';
  loginForm!: FormGroup;
  resetPasswordForm!: FormGroup;
  returnUrl!: string; //(for redirect next js application)
  // isLoading: any = false;
  passwordFieldType: string = 'password';
  confirmPasswordFieldType: string = 'password';
  key: string = USER_SECRATE; //next js application

  otp = signal<Otp>({
    pin: 0,
    showOtp: false,
    isLoading: false,
    otpError: '',
    otpSuccess: '',
  });
  btnLoading: boolean = false;

  // resetModalShow: boolean = false;
  // resetLoading: boolean = false;

  // defaultAuth: any = {
  //   mobileNo: '',
  //   password: '',
  // };

  // loginDto: ExtendedLoginDto = loginDto as ExtendedLoginDto;
  // hasError: boolean = false;

  // subs = new SubSink();
  // changePasswordShow: boolean = false;

  // loginResponse: any;
  // resetFormSubmitted: boolean = false;
  // resetPasswordFieldType = 'password';
  // resetConfPasswordFieldType = 'password';

  constructor(
    private fb: FormBuilder,
    private Router: Router,
    private ActivatedRoute: ActivatedRoute,
    private NormalAuth: AuthService,
    private UserManageAccountsService: UserManageAccountsService,
    private TosterService: TosterService,
    private PatientProfileService: PatientProfileService,
    private Auth: Auth,
    private DoctorProfileService: DoctorProfileService
  ) {}

  ngOnInit(): void {
    this.initForm();
    const returnUrl =
      this.ActivatedRoute.snapshot.queryParamMap.get('returnUrl');
    this.returnUrl = decodeURIComponent(returnUrl || '');

    this.ActivatedRoute.queryParams.subscribe((params) => {
      this.selectedUserType = params['userType'];
    });
  }

  get formControl() {
    return this.loginForm.controls;
  }

  initForm() {
    this.loginForm = this.fb.group({
      mobileNo: [
        '',
        [
          Validators.required,
          Validators.pattern(/(^(\+88|0088)?(01){1}[3456789]{1}(\d){8})$/),
        ],
      ],
      password: [''],
    });
  }

  onOtpChange(pin: string): void {
    if (pin.length === 4) {
      this.otp().pin = parseInt(pin);
      this.verify();
    }
  }
  sendOtp() {
    this.btnLoading = true;
    this.otp.update((p) => ({ ...p, otpError: '' }));
    let otpData: SendOtpModel = {
      userName: this.loginForm.get('mobileNo')?.value,
      roleId: this.selectedUserType,
      type: 2,
    };
    if (this.selectedUserType === 'Doctor') {
      return this.onSubmit();
    } else {
      try {
        this.UserManageAccountsService.sendOtpByRequest(otpData).subscribe({
          next: (res) => {
            if (res.is_success) {
              this.otp.update((p) => ({
                ...p,
                isLoading: false,
                showOtp: true,
              }));
            } else if (res.message) {
              console.log(res);

              this.otp.update((p) => ({
                ...p,
                isLoading: false,
                showOtp: false,
                otpError: res.message,
              }));
              this.btnLoading = false;
            } else {
              this.otp.update((p) => ({
                ...p,
                isLoading: false,
                showOtp: false,
                otpError: 'Something went worng!',
              }));
              this.btnLoading = false;
            }
          },
          error: () => {
            this.otp.update((p) => ({
              ...p,
              isLoading: false,
              showOtp: false,
              otpError: 'Failed to send otp!',
            }));
            this.btnLoading = false;
          },
        });
      } catch (error) {
        console.log(error);
      }
    }
  }

  verify(): void {
    this.otp.update((p) => ({ ...p, isLoading: true, otpError: '' }));

    const otpRequiest: OtpRequestDto = {
      otp: this.otp().pin,
      mobileNo: this.loginForm.get('mobileNo')?.value,
    };

    this.UserManageAccountsService.verifyOtpByRequest(otpRequiest).subscribe({
      next: (otpResponse) => {
        if (otpResponse.is_success) {
          this.onSubmit();
          //this.otp().otpSuccess = otpResponse.message;
        } else {
          this.otp.update((p) => ({
            ...p,
            otpError: otpResponse.message,
            showOtp: true,
            isLoading: false,
          }));
          // this.otp().otpError = otpResponse.message;
        }
      },
      error: (err) => {
        this.otp().otpError = 'Failed to verify OTP. Please try again.';
        this.otp().isLoading = false;
        console.error('OTP verification failed:', err);
      },
    });
  }
  goToForgotPassword() {
    this.Router.navigate(['/forgot-password'], {
      queryParams: { redirect: 'login' },
    });
  }
  navigateToNextJS(redirect: string) {}
  handleProfileError(error: any): void {}
  passwordVisibility(field: string) {
    if (field === 'password') {
      this.passwordFieldType =
        this.passwordFieldType === 'password' ? 'text' : 'password';
    } else if (field === 'confirmPassword') {
      this.confirmPasswordFieldType =
        this.confirmPasswordFieldType === 'password' ? 'text' : 'password';
    }
  }
  onSubmit(): void {
    this.otp.update((p) => ({
      ...p,
      otpError: '',
    }));
    const redirect = this.ActivatedRoute.snapshot.queryParams['redirect'];
    const requestLogin: UserSignInRequestDto = {
      userName: this.loginForm.get('mobileNo')?.value,
      password:
        this.selectedUserType == 'Patient'
          ? 'Default@123'
          : this.formControl['password'].value,
    };

    try {
      this.Auth.loginApiByRequest(requestLogin).subscribe({
        next: (userInfo) => {
          console.log(userInfo);

          if (userInfo.is_success) {
            if (userInfo.results.roleName[0].toLowerCase() === 'doctor') {
              this.handleDoctorProfile(userInfo.results);
            }
            if (userInfo.results.roleName[0].toLowerCase() === 'patient') {
              this.handlePatientProfile(userInfo.results);
            }
          }

          if (!userInfo.is_success) {
            this.otp.update((p) => ({
              ...p,
              isLoading: false,
              otpError: 'User not found!',
            }));
            this.btnLoading = false;
            // this.TosterService.customToast('User not found', 'error');
          }
        },
        error: (errorResponse) => {
          const errorMessage =
            errorResponse.error.error_description ||
            errorResponse.error.message;
          this.btnLoading = false;
          this.TosterService.customToast(errorMessage, 'error');
        },
      });
    } catch (error: any) {
      console.log(error);

      this.btnLoading = false;
      this.TosterService.customToast(error, 'error');
    }
  }

  // TODO should work on error handeling
  handlePatientProfile(userInfo: LoginResponseDto) {
    if (userInfo.userName) {
      this.PatientProfileService.getByUserName(userInfo.userName).subscribe(
        (patientDto: PatientProfileDto) => {
          let saveLocalStorage = {
            fullName: patientDto.fullName,
            userId: patientDto.userId,
            id: patientDto.id,
            userType: userInfo.roleName[0].toLowerCase(),
          };
          this.NormalAuth.setAuthInfoInLocalStorage(saveLocalStorage);
          localStorage.setItem(
            'accessToken',
            JSON.stringify(userInfo.accessToken)
          );
          localStorage.setItem(
            'refreshToken',
            JSON.stringify(userInfo.accessToken)
          );
          let userType = userInfo.roleName[0].toLowerCase() + '/dashboard';
          this.Router.navigate([userType], {
            state: { data: patientDto },
          });
          this.otp.update((p) => ({
            ...p,
            isLoading: false,
          }));
        },
        (patientError: any) => {
          this.handleProfileError(patientError);
        }
      );
    } else {
      this.otp.update((p) => ({
        ...p,
        isLoading: false,
      }));
      this.TosterService.customToast('Username not found!', 'error');
    }
  }

  handleDoctorProfile(userInfo: LoginResponseDto) {
    if (userInfo.userName) {
      this.DoctorProfileService.getByUserName(userInfo.userName).subscribe({
        next: (patientDto: PatientProfileDto) => {
          let saveLocalStorage = {
            fullName: patientDto.fullName,
            userId: patientDto.userId,
            id: patientDto.id,
            //userpc: this.encrypt(String(patientDto.mobileNo)),
            userType: userInfo.roleName[0].toLowerCase(),
          };
          this.NormalAuth.setAuthInfoInLocalStorage(saveLocalStorage);
          let userType = userInfo.roleName[0].toLowerCase() + '/dashboard';
          this.Router.navigate([userType.toLowerCase()], {
            state: { data: patientDto },
          });
          this.otp.update((p) => ({
            ...p,
            isLoading: false,
          }));
          // if (redirect) {
          //   this.navigateToNextJS(redirect);
          // } else if (this.returnUrl) {
          //   this._router.navigate([this.returnUrl]);
          //   return;
          // } else {
          //   this._router.navigate([userType.toLowerCase()], {
          //     state: { data: patientDto },
          //   });
          // }
          this.NormalAuth.setOtpLoader(false);
        },
        error: (patientError: any) => {
          this.handleProfileError(patientError);
        },
      });
    } else {
      this.otp.update((p) => ({
        ...p,
        isLoading: false,
      }));
      this.TosterService.customToast('Username not found!', 'error');
    }
  }

  // if (userInfo.loginData.roleName === 'Patient') {
  //   this.isLoading = false;
  //   this.otpLoader = false;
  //   this.subs.sink = this.PatientProfileService.getByUserName(
  //     userInfo.loginData.userName ? userInfo.loginData.userName : ''
  //   ).subscribe(
  //     (patientDto: PatientProfileDto) => {
  //       let saveLocalStorage = {
  //         fullName: patientDto.fullName,
  //         userId: patientDto.userId,
  //         id: patientDto.id,
  //         userpc: this.encrypt(String(patientDto.mobileNo)),
  //         userType: userInfo.loginData.roleName
  //           .toString()
  //           .toLowerCase(),
  //       };
  //       this.NormalAuth.setAuthInfoInLocalStorage(saveLocalStorage);
  //       let userType =
  //         userInfo.loginData.roleName.toString() + '/dashboard';

  //       if (redirect) {
  //         this.navigateToNextJS(redirect);
  //       } else if (this.returnUrl) {
  //         this._router.navigate([this.returnUrl]);
  //         return;
  //       } else {
  //         this._router.navigate([userType.toLowerCase()], {
  //           state: { data: patientDto },
  //         });
  //       }
  //       this.NormalAuth.setOtpLoader(false);
  //     },
  //     (patientError: any) => {
  //       // Handle PatientProfile service error
  //       this.handleProfileError(patientError);
  //     }
  //   );
  // }

  // if (userInfo.loginData.roleName == 'Doctor') {
  //   this.isLoading = false;
  //   this.NormalAuth.setOtpLoader(false);
  //   this.subs.sink = this.doctorProfileService
  //     .getByUserName(
  //       userInfo.loginData.userName ? userInfo.loginData.userName : ''
  //     )
  //     .subscribe(
  //       (doctorDto: DoctorProfileDto) => {
  //         this.otpLoader = false;
  //         let saveLocalStorage = {
  //           identityNumber: doctorDto.identityNumber,
  //           fullName: doctorDto.fullName,
  //           bmdcRegNo: doctorDto.bmdcRegNo,
  //           isActive: doctorDto.isActive,
  //           userId: doctorDto.userId,
  //           id: doctorDto.id,
  //           specialityId: doctorDto.specialityId,
  //           profileStep: doctorDto.profileStep,
  //           createFrom: doctorDto.createFrom,
  //           userType: userInfo.loginData.roleName
  //             .toString()
  //             .toLowerCase(),
  //         };
  //         this.NormalAuth.setAuthInfoInLocalStorage(saveLocalStorage);
  //         if (
  //           doctorDto.profileStep == 1 ||
  //           doctorDto.profileStep == 2
  //         ) {
  //           userType = '/signup';
  //           //this._router.navigate(['/signup']);
  //         } else {
  //           userType = doctorDto.isActive
  //             ? userInfo.loginData.roleName.toString() + '/dashboard'
  //             : userInfo.loginData.roleName.toString() +
  //               '/profile-settings/basic-info';
  //         }

  //         if (redirect) {
  //           this.navigateToNextJS(redirect);
  //         } else {
  //           this._router.navigate([userType.toLowerCase()], {
  //             state: { data: doctorDto },
  //           });
  //         }
  //         this.NormalAuth.setOtpLoader(false);
  //       },
  //       (doctorError: any) => {
  //         // Handle DoctorProfile service error

  //         this.handleProfileError(doctorError);
  //       }
  //     );
  // }

  // if (userInfo.loginData?.roleName === 'Agent') {
  //   this.hasError = true;
  //   this.isLoading = false;
  //   this.otpLoader = false;
  //   this.NormalAuth.setOtpLoader(false);
  //   this.errorMessage =
  //     'You are a Agent!. Please login from Agent portal.';
  // }

  // sendOtp() {
  //   this.isLoading = true;
  //   let otpData = {
  //     userName: this.loginForm.get('mobileNo')?.value,
  //     type: 'login',
  //   };
  //   if (this.selectedUserType === 'doctor') {
  //     return this.onSubmit();
  //   } else {
  //     this.NormalAuth.sendOtp(otpData).subscribe({
  //       next: (res) => {
  //         if (res.message) {
  //           this.isLoading = false;
  //           this.showOtp = true;
  //           console.log('successfully send otp');
  //         }
  //       },
  //       error: (err) => {
  //         console.log(err);
  //         this.errorMessage = 'User not exist! ';
  //         this.isLoading = false;
  //         this.showOtp = false;
  //         console.error('failed to send otp');
  //       },
  //     });
  //   }
  // }
  // verify() {
  //   this.errorMessage = '';
  //   this.otpLoader = true;
  //   let otp = this.otp;
  //   if (otp) {
  //     this.NormalAuth.setOtpLoader(true);
  //     this.OtpService.varifyOtp(Number(otp)).subscribe((res: boolean) => {
  //       if (res) {
  //         this.onSubmit();
  //       } else {
  //         this.isLoading = false;
  //         this.errorMessage = 'Invalid Otp!';
  //         this.NormalAuth.setOtpLoader(false);
  //       }
  //     });
  //   }
  // }

  // onOtpChange(pin: string) {
  //   if (pin.length == 4) {
  //     this.otp = Number(pin);
  //     this.otpLoader = true;
  //     this.onSubmit();
  //   } else {
  //     console.log('Pin should be 4 character');
  //   }
  // }

  // onSubmit(): void {
  //   const redirect = this.ActivatedRoute.snapshot.queryParams['redirect'];

  //   this.formSubmitted = true;

  //   if (!this.loginForm.valid && !this.loginForm.touched) {
  //     this.ToasterService.customToast(
  //       'Please fill in all required fields',
  //       'warning'
  //     );
  //     this.isLoading = false;
  //     return;
  //   } else {
  //     if (this.loginForm.invalid) {
  //       this.isLoading = false;
  //       return;
  //     }

  //     this.formSubmitted = false;
  //     this.isLoading = true;
  //     let userType = '';
  //     this.errorMessage = '';
  //     this.hasError = false;
  //     const username = this.formControl['mobileNo'].value;
  //     const password =
  //       this.selectedUserType == 'patient'
  //         ? 'Coppa@123'
  //         : this.formControl['password'].value;

  //     this.oAuthService.oidc = false;
  //     this.loginDto.userName = username;
  //     this.loginDto.password = password;
  //     this.loginDto.otp = this.selectedUserType == 'patient' ? this.otp : null;

  //     try {
  //       this.appAuthService.isLoadingSubject.next(true);
  //       this.NormalAuth.signin(this.loginDto).subscribe({
  //         next: (userInfo) => {
  //           if (userInfo.loginData.roleName === 'Patient') {
  //             this.isLoading = false;
  //             this.otpLoader = false;
  //             this.subs.sink = this.PatientProfileService.getByUserName(
  //               userInfo.loginData.userName ? userInfo.loginData.userName : ''
  //             ).subscribe(
  //               (patientDto: PatientProfileDto) => {
  //                 let saveLocalStorage = {
  //                   fullName: patientDto.fullName,
  //                   userId: patientDto.userId,
  //                   id: patientDto.id,
  //                   userpc: this.encrypt(String(patientDto.mobileNo)),
  //                   userType: userInfo.loginData.roleName
  //                     .toString()
  //                     .toLowerCase(),
  //                 };
  //                 this.NormalAuth.setAuthInfoInLocalStorage(saveLocalStorage);
  //                 let userType =
  //                   userInfo.loginData.roleName.toString() + '/dashboard';

  //                 if (redirect) {
  //                   this.navigateToNextJS(redirect);
  //                 } else if (this.returnUrl) {
  //                   this._router.navigate([this.returnUrl]);
  //                   return;
  //                 } else {
  //                   this._router.navigate([userType.toLowerCase()], {
  //                     state: { data: patientDto },
  //                   });
  //                 }
  //                 this.NormalAuth.setOtpLoader(false);
  //               },
  //               (patientError: any) => {
  //                 // Handle PatientProfile service error
  //                 this.handleProfileError(patientError);
  //               }
  //             );
  //           }

  //           if (userInfo.loginData.roleName == 'Doctor') {
  //             this.isLoading = false;
  //             this.NormalAuth.setOtpLoader(false);
  //             this.subs.sink = this.doctorProfileService
  //               .getByUserName(
  //                 userInfo.loginData.userName ? userInfo.loginData.userName : ''
  //               )
  //               .subscribe(
  //                 (doctorDto: DoctorProfileDto) => {
  //                   this.otpLoader = false;
  //                   let saveLocalStorage = {
  //                     identityNumber: doctorDto.identityNumber,
  //                     fullName: doctorDto.fullName,
  //                     bmdcRegNo: doctorDto.bmdcRegNo,
  //                     isActive: doctorDto.isActive,
  //                     userId: doctorDto.userId,
  //                     id: doctorDto.id,
  //                     specialityId: doctorDto.specialityId,
  //                     profileStep: doctorDto.profileStep,
  //                     createFrom: doctorDto.createFrom,
  //                     userType: userInfo.loginData.roleName
  //                       .toString()
  //                       .toLowerCase(),
  //                   };
  //                   this.NormalAuth.setAuthInfoInLocalStorage(saveLocalStorage);
  //                   if (
  //                     doctorDto.profileStep == 1 ||
  //                     doctorDto.profileStep == 2
  //                   ) {
  //                     userType = '/signup';
  //                     //this._router.navigate(['/signup']);
  //                   } else {
  //                     userType = doctorDto.isActive
  //                       ? userInfo.loginData.roleName.toString() + '/dashboard'
  //                       : userInfo.loginData.roleName.toString() +
  //                         '/profile-settings/basic-info';
  //                   }

  //                   if (redirect) {
  //                     this.navigateToNextJS(redirect);
  //                   } else {
  //                     this._router.navigate([userType.toLowerCase()], {
  //                       state: { data: doctorDto },
  //                     });
  //                   }
  //                   this.NormalAuth.setOtpLoader(false);
  //                 },
  //                 (doctorError: any) => {
  //                   // Handle DoctorProfile service error

  //                   this.handleProfileError(doctorError);
  //                 }
  //               );
  //           }

  //           if (userInfo.loginData?.roleName === 'Agent') {
  //             this.hasError = true;
  //             this.isLoading = false;
  //             this.otpLoader = false;
  //             this.NormalAuth.setOtpLoader(false);
  //             this.errorMessage =
  //               'You are a Agent!. Please login from Agent portal.';
  //           }
  //         },
  //         error: (errorResponse) => {
  //           this.hasError = true;
  //           this.appAuthService.isLoadingSubject.next(false);
  //           this.otpLoader = false;
  //           this.errorMessage =
  //             errorResponse.error.error_description ||
  //             errorResponse.error.message;
  //           this.isLoading = false;
  //           this.NormalAuth.setOtpLoader(false);
  //         },
  //       });
  //     } catch (error: any) {
  //       this.otpLoader = false;
  //       this.hasError = true;
  //       if (error.message === "'tokenEndpoint' should not be null") {
  //         this.errorMessage = 'Identity server is not running';
  //       }
  //     }
  //   }
  // }
  // private encrypt(user: string): string {
  //   return CryptoJS.AES.encrypt(user, this.key).toString();
  // }
  // navigateToNextJS(redirect: string) {
  //   const jwtToken = localStorage.getItem('access_token'); // Get your JWT token from somewhere

  //   window.open(
  //     `http://localhost:3000/${redirect}?token=${jwtToken}`,
  //     '_blank'
  //   );
  // }

  // private handleProfileError(error: any): void {
  //   this.errorMessage = '';
  //   if (
  //     error.error.error.message ===
  //     'There is no entity DoctorProfile with id = !'
  //   ) {
  //     this.errorMessage = 'User not found';
  //   } else {
  //     this.ToasterService.customToast(
  //       String(error.error.error.message),
  //       'error'
  //     );
  //   }
  // }

  updateQueryParam(userType: string) {
    const queryParams =
      userType !== '' ? { userType: userType } : { userType: null };
    this.Router.navigate([], {
      relativeTo: this.ActivatedRoute,
      queryParams: queryParams,
      queryParamsHandling: 'merge',
    });
  }

  backToPrevious() {
    this.updateQueryParam('');
  }
  ngOnDestroy() {
    // this.subs.unsubscribe();
  }
}
