import { NgModule } from '@angular/core';
import { ExtraOptions, Route, RouterModule } from '@angular/router';
import { routerGuard } from './auth-gurd/router.guard';
import { LoginComponent } from './core-modules/auth/login/login.component';
import { EmptyPageComponent } from './features-modules/public/empty-page/empty-page.component';
import { PublicLayoutComponent } from './layout-module/layouts/public-layout/public-layout.component';
import { PublicLayoutModule } from './layout-module/layouts/public-layout/public-layout.module';

export const appRoutes: Route[] = [
  {
    path: '',
    component: PublicLayoutComponent,
    // loadChildren: () =>
    //   import('./features-modules/public/landing-page/landing-page.module').then(
    //     (m) => m.LandingPageModule
    //   ),
  },

  {
    path: 'login',
    canActivate: [routerGuard],
    component: LoginComponent,
  },
  {
    path: 'forgot-password',
    loadChildren: () =>
      import(
        './features-modules/public/forgot-password/forgot-password.module'
      ).then((m) => m.ForgotPasswordModule),
  },
  {
    path: 'auth-checking',
    loadChildren: () =>
      import('./core-modules/auth/auth-checking/auth-checking.module').then(
        (m) => m.AuthCheckingModule
      ),
  },
  {
    path: 'service-login',
    loadChildren: () =>
      import('./core-modules/auth/service-login/service-login.module').then(
        (m) => m.ServiceLoginModule
      ),
  },
  {
    path: 'signup',
    //canActivate:[routerGuard],
    loadChildren: () =>
      import('./core-modules/auth/signup-o/signup.module').then(
        (m) => m.SignupModule
      ),
    // component: SignupComponent,
  },
  {
    path: 'agent/signup',
    canActivate: [routerGuard],
    loadChildren: () =>
      import('./core-modules/auth/agent/agent-signup/agent-signup.module').then(
        (m) => m.AgentSignupModule
      ),
  },
  {
    path: 'agent/login',
    canActivate: [routerGuard],
    loadChildren: () =>
      import('./core-modules/auth/agent/agent-login/agent-login.module').then(
        (m) => m.AgentLoginModule
      ),
  },
  {
    path: '**',
    component: EmptyPageComponent,
  },
];

const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'enabled', // আগের পজিশনে স্ক্রল নিয়ে যাবে
  anchorScrolling: 'enabled', // ইন-পেজ অ্যাংকর লিঙ্কের জন্য স্ক্রল কাজ করবে
  scrollOffset: [0, 64],
  // হেডার থাকলে এটি ব্যাবহার করুন (x, y)
};

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      useHash: false,
    }),
    PublicLayoutModule,
  ],
  exports: [RouterModule],
})
export class AppRouting {}
