<ng-container *ngIf="selectedUserType">
  <app-login-input />
</ng-container>
<ng-container *ngIf="!selectedUserType">
  <div class="py-8 max-w-[500px] mx-auto flex gap-3 flex-wrap justify-center">
    <button (click)="selectUser('Patient')"
      class="bg-white min-w-[200px] max-w-[220px] border-gray-300 px-2 items-center flex gap-2 border-[1px] py-2 shadow-blue hover:border-secondary hover:text-secondary duration-200 text-sm rounded-md text-primary font-poppins w-full">
      <img class="w-[35px] h-full" src="assets/patients/patient.png" alt="img" />
      Login as a Patient
    </button>
    <!-- <p class="text-center hidden xl:block font-bold mx-auto py-2">or</p> -->
    <button (click)="selectUser('Doctor')"
      class="bg-white min-w-[200px] max-w-[220px] border-gray-300 px-2 items-center flex gap-2 border-[1px] py-2 shadow-blue hover:border-secondary hover:text-secondary duration-200 text-sm rounded-md text-primary font-poppins w-full">
      <img class="w-[35px] h-full" src="assets/auth/doctor.png" alt="img" />

      Login as a Doctor
    </button>
  </div>
</ng-container>