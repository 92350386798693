import { animate, style, transition, trigger } from '@angular/animations';
import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AuthService } from './../../../shared/services/auth.service';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
  animations: [
    trigger('fadeInBlur', [
      transition(':enter', [
        style({ opacity: 0, filter: 'blur(5px)' }), // Start with opacity 0 and blur
        animate('500ms ease-out', style({ opacity: 1, filter: 'blur(0)' })), // Animate to full opacity and sharpness
      ]),
    ]),
  ],
})
export class LandingPageComponent implements OnInit {
  model: any;
  bottomBtn!: boolean;
  @ViewChild('smoothContent', { static: false }) smoothContent!: ElementRef;
  scroll: any;
  isShowOffer = true;
  constructor(private titleService: Title, private AuthService: AuthService) {}

  ngOnInit() {
    let userType = this.AuthService.authInfo()?.userType;
    if (userType == 'agent' || userType == 'doctor') {
      this.isShowOffer = false;
    } else {
      this.isShowOffer = true;
    }
    this.titleService.setTitle(
      ` Soowgood | Online health care and telemedicine service in Bangladesh.`
    );
    window.scrollTo(0, 0);
    this.model = {
      firstname: {
        type: 'text',
        value: '',
        label: 'FirstName',
        rules: {
          required: true,
        },
      },
      lastname: {
        type: 'text',
        value: '',
        label: 'LastName',
        rules: {
          required: true,
        },
      },
      address: {
        type: 'text',
        value: '',
        label: 'Address',
        rules: {
          required: true,
        },
      },
      age: {
        type: 'number',
        value: '',
        label: 'age',
        rules: {
          required: true,
        },
      },
      birthDay: {
        type: 'date',
        value: '',
        label: 'Birthday',
        rules: {
          required: true,
        },
      },
    };
  }
  @HostListener('window:scroll', ['$event'])
  onWindowScroll(): void {
    const scrollPosition =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;

    if (scrollPosition > 600) {
      this.bottomBtn = true;
    } else {
      this.bottomBtn = false;
    }
  }
}
