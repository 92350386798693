<section class="flex flex-col md:flex-row items-center justify-center py-10 min-h-screen bg-white">
    <div class="md:w-1/2 flex flex-col items-center justify-center px-6 md:px-12">
        <img routerLink="/" src="assets/auth/clr-logo.png" alt="Soowgood Logo" class="mb-8 max-w-[200px]" />
        <h2 class="text-[26px] lg:text-left text-center lg:text-[32px] font-RobotoBold text-primary mb-4">
            Welcome to our platform
        </h2>
        <p class="text-gray-600 font-poppinsLight text-sm max-w-[450px] text-center mb-4">
            Please let us know the type of account you would like to open on our
            telemedicine platform.
        </p>


        <app-login-process class="w-full" />
        <div class="max-w-[400px] text-center">
            <h3 class="text-xl font-semibold text-gray-800 mb-4">Are you Agent?</h3>
            <p class="text-gray-600 mb-4">Click here if you are an agent</p>
            <a routerLink="/agent" class="inline-block font-poppins btn-secondary">
                Agent Login
            </a>
        </div>
        <p class="mt-5">
            Create a new account
            <strong routerLink="/signup" class="cursor-pointer">Signup</strong>
        </p>
    </div>

    <div class="md:w-1/2 hidden md:flex justify-start items-center">
        <img src="assets/auth/auth-login.svg" alt="Login Illustration" class="max-w-full h-auto" />
    </div>
</section>